import React, { useState, useEffect, useRef } from 'react';
import './contact.css';
import { useLanguage } from '../../LanguageContext'; // Import the context
import emailjs from 'emailjs-com'; // Import EmailJS

import sendButtonImage from '../../assets/send.png'; // Replace with the actual path
import sendBtnTurkishImage from '../../assets/send2.png'; // Replace with the actual path

import Aos from 'aos';
import 'aos/dist/aos.css';

const Contact = () => {
  const { language } = useLanguage(); // Get the current language
  const formRef = useRef(null); // Create a ref for the form

  const translations = {
    en: {
      followUs: '[follow us]',
      contactUs: '[contact us]',
      name: 'Name',
      email: 'Email',
      contact: 'Contact',
      message: 'Message',
      sentMessage: 'Your message has been sent successfully!',
      errorMessage: 'There was an error sending your message. Please try again.',
    },
    tr: {
      followUs: '[bizi takip edin]',
      contactUs: '[bize ulaşın]',
      name: 'Adınız',
      email: 'E-posta',
      contact: 'Telefon Numaranız',
      message: 'Mesaj',
      sentMessage: 'Mesajınız başarıyla gönderildi!',
      errorMessage: 'Mesajınızı gönderirken bir hata oluştu. Lütfen tekrar deneyin.',
    },
  };

  const t = translations[language];
  const [formState, setFormState] = useState({ from_name: '', email: '', contact: '', message: '' });
  const [status, setStatus] = useState('');

  const handleChange = (e) => {
    setFormState({ ...formState, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    emailjs.sendForm(
      process.env.REACT_APP_EMAILJS_SERVICE_ID,
      process.env.REACT_APP_EMAILJS_TEMPLATE_ID,
      formRef.current,
      process.env.REACT_APP_EMAILJS_USER_ID
    )
      .then((result) => {
        setStatus(t.sentMessage);
        setFormState({ from_name: '', email: '', contact: '', message: '' }); // Clear form
      }, (error) => {
        console.error('EmailJS Error:', error.text); // Log the error message
        setStatus(t.errorMessage);
      });
  };

  const handleButtonClick = () => {
    if (formRef.current) {
      formRef.current.dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }));
    }
  };

  useEffect(() => {
    Aos.init({ duration: 1000 });
  }, []);

  return (
    <div className="contact-and-social">
      {/* Social Media Section */}
      <div className="box-container">
        <h2 data-aos='fade-up'>{t.followUs}</h2>
        <div className="social-media">
          <div className="social-item">
            <a href="https://www.instagram.com/mdotcandles?igsh=MTZ3cmlqZml5azltdA%3D%3D&utm_source=qr" target="_blank" rel="noopener noreferrer">
            Instagram↗
            </a>
          </div>
          <div className="social-item">
            <a href="https://www.tiktok.com/discover/mdot-candles" target="_blank" rel="noopener noreferrer">
            Tik Tok↗
            </a>
          </div>
          <div className="social-item">
            <a href="https://pin.it/2mwXc5wop" target="_blank" rel="noopener noreferrer">
            Pinterest↗
            </a>
          </div>
        </div>
      </div>

      {/* Contact Form Section */}
      <div className="box-container">
        <h2 data-aos='fade-up'>{t.contactUs}</h2>
        <div className="contact-form">
          <form ref={formRef} onSubmit={handleSubmit}>
            <input
              type="text"
              name="from_name" // Ensure this matches the EmailJS template variable
              placeholder={t.name}
              value={formState.from_name}
              onChange={handleChange}
              required
            />
            <input
              type="email"
              name="email"
              placeholder={t.email}
              value={formState.email}
              onChange={handleChange}
              required
            />
            <input
              type="text"
              name="contact"
              placeholder={t.contact}
              value={formState.contact}
              onChange={handleChange}
              required
            />
            <textarea
              name="message"
              placeholder={t.message}
              value={formState.message}
              onChange={handleChange}
              required
            ></textarea>
          </form>
        </div>
        <div className="send-button">
          <img
            src={language === 'tr' ? sendBtnTurkishImage : sendButtonImage}
            alt="Send Button"
            onClick={handleButtonClick}
          />
        </div>
        {status && <div className="status-message">{status}</div>}
        {/* Copyright Text */}
        <div className="copyright">ⓒ M.Candles 2024</div>
      </div>
    </div>
  );
};

export default Contact;
