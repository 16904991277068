import React, { useEffect } from 'react';
import './essence.css';
import candleScentFilm from '../../assets/candleScentFilm.mp4';
import { useLanguage } from '../../LanguageContext';

import Aos from 'aos';
import 'aos/dist/aos.css';

const Essence = () => {
  const { language } = useLanguage();

  const content = {
    en: {
      title: "[the scent]",
      description: "Indulge in a harmonious blend of Sandalwood, Jasmine, and Lemon.",
      items: [
        "Let the warmth of sandalwood bring a sense of grounding & tranquility.",
        "Jasmine whispers of relaxation as its soothing aroma fills the air.",
        "Zesty lemon adds a refreshing twist, uplifting your spirits."
      ]
    },
    tr: {
      title: "[esans]",
      description: "Keyifli bir karışımın tadını çıkarın: Sandal ağacı, Yasemin ve Limon.",
      items: [
        "Sandal ağacının sıcak kucaklamasına kapılın, sizi sağlamlaştırma ve huzur hissiyle doldurur.",
        "Yasemin, rahatlama telkin eder, yatıştırıcı kokusu havayı doldurur.",
        "Limon ferahlatıcı bir dokunuş katarken, ruhunuzu yüceltir."
      ]
    }
  };

  const { title, description, items } = content[language];

  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);

  return (
    <div className="essence-container">
      <div className="essence-content">
        <h1 data-aos='fade-up'>{title}</h1>
        <p data-aos='fade-up'>{description}</p>
        <ul data-aos='fade-up'>
          {items.map((item, index) => (
            <li key={index}>{item}</li>
          ))}
        </ul>
      </div>
      <div className="essence-video">
        <video autoPlay loop muted>
          <source src={candleScentFilm} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </div>
    </div>
  );
}

export default Essence;
