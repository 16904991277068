import React, { useEffect } from 'react';
import './stockists.css';
import trendyoImage from '../../assets/trendyol.png'; // Adjust the import according to your file structure
import trendyoLogo from '../../assets/trendyol_logo.png'; // Title image for Trendyol
import shopierImage from '../../assets/shopier.png'; // Adjust the import according to your file structure
import shopierLogo from '../../assets/shopier_logo.png'; // Title image for Shopier
import { useLanguage } from '../../LanguageContext'; // Import the useLanguage hook

import Aos from 'aos';
import 'aos/dist/aos.css';

const Stockists = () => {
  const { language } = useLanguage(); // Get the current language from the context

  // Define the text based on the language
  const text = {
    en: '[our stockists]',
    tr: '[bayilerimiz]'
  };

  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);

  return (
    <div className="stockists">
      {/* Left Column */}
      <div className="stockists-left">
        <div className="stockists-left-top">
          <h2>{text[language]}</h2>
        </div>
        <div className="stockists-left-bottom"></div>
      </div>
      
      {/* Right Column */}
      <div className="stockists-right">
        <div className="stockist-item">
          <a href="https://www.trendyol.com/magaza/m-candles-m-973914?sst=0" target="_blank" rel="noopener noreferrer">
            <img src={trendyoLogo} alt="Trendyol Logo" className="stockist-title-image" />
          </a>
          <a href="https://www.trendyol.com/magaza/m-candles-m-973914?sst=0" target="_blank" rel="noopener noreferrer">
            <img src={trendyoImage} alt="Trendyol" className="stockist-image" />
          </a>
        </div>
        <div className="stockist-item">
          <a href="https://www.shopier.com/ShowProductNew/storefront.php?shop=mdotcandles&sid=b3M0SXd2MnVWWW9FVjZaSTBfLTFfIF8g" target="_blank" rel="noopener noreferrer">
            <img src={shopierLogo} alt="Shopier Logo" className="stockist-title-image" />
          </a>
          <a href="https://www.shopier.com/ShowProductNew/storefront.php?shop=mdotcandles&sid=b3M0SXd2MnVWWW9FVjZaSTBfLTFfIF8g" target="_blank" rel="noopener noreferrer">
            <img src={shopierImage} alt="Shopier" className="stockist-image" />
          </a>
        </div>
      </div>
    </div>
  );
};

export default Stockists;
