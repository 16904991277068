import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUp } from '@fortawesome/free-solid-svg-icons';
import './scrollToHomeButton.css';

const ScrollToHomeButton = () => {
  const [isVisible, setIsVisible] = useState(false);

  const toggleVisibility = () => {
    const element = document.getElementById('scroll-to-home-button');
    if (element) {
      const rect = element.getBoundingClientRect();
      if (window.scrollY > rect.height) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', toggleVisibility);
    return () => window.removeEventListener('scroll', toggleVisibility);
  }, []);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  return (
    <div
      id="scroll-to-home-button"
      className={`scroll-to-home ${isVisible ? 'visible' : ''}`}
      onClick={scrollToTop}
      style={{ display: isVisible ? 'block' : 'none' }}
    >
      <FontAwesomeIcon icon={faArrowUp} size="2x" />
    </div>
  );
};

export default ScrollToHomeButton;
