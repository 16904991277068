import React from 'react';
import './App.css';
import Home from './components/Home/Home';
import About from './components/About/About';
import Wellness from './components/Wellness/Wellness';
import Showcase from './components/Showcase/Showcase';
import Essence from './components/Essence/Essence';
import CrystalCollection from './components/CrystalCollection/CrystalCollection';
import Stockists from './components/Stockists/Stockists';
import Contact from './components/Contact/Contact';
import ZodiacCollection from './components/ZodiacCollection/ZodiacCollection';
import ScrollToHomeButton from './components/ScrollToHomeButton/ScrollToHomeButton';


function App() {
  return (
    <div className="App">
      <ScrollToHomeButton />
      <Home />
      <About />
      <Wellness />
      <Showcase />
      <Essence />
      <CrystalCollection />
      <ZodiacCollection />
      <Stockists />
      <Contact />
    </div>
  );
}

export default App;
