import React, { useEffect, useState } from 'react';
import './zodiacCollection.css';

import aries from '../../assets/aries.png'; 
import taurus from '../../assets/taurus.png';
import gemini from '../../assets/gemini.png';
import cancer from '../../assets/cancer.png';
import leo from '../../assets/leo.png';
import virgo from '../../assets/virgo.png';
import libra from '../../assets/libra.png';
import scorpio from '../../assets/scorpio.png';
import sagittarius from '../../assets/sagittarius.png';
import capricorn from '../../assets/capricorn.png';
import aquarius from '../../assets/aquarius.png';
import pisces from '../../assets/pisces.png';

import { useLanguage } from '../../LanguageContext';

import aries2 from '../../assets/aries2.jpg'; 
import taurus2 from '../../assets/taurus2.jpg';
import gemini2 from '../../assets/gemini2.jpg';
import cancer2 from '../../assets/cancer2.jpg';
import leo2 from '../../assets/leo2.jpg';
import virgo2 from '../../assets/virgo2.jpg';
import libra2 from '../../assets/libra2.jpg';
import scorpio2 from '../../assets/scorpio2.jpg';
import sagittarius2 from '../../assets/sagittarius2.jpg';
import capricorn2 from '../../assets/capricorn2.jpg';
import aquarius2 from '../../assets/aquarius2.jpg';
import pisces2 from '../../assets/pisces2.jpg';

import Aos from 'aos';
import 'aos/dist/aos.css';

const zodiacs = {
  en: [
    { name: 'Aries [21 March - 19 April]', description: 'Rose Quartz, Amethyst, Crystal Quartz', image: aries, hoverImage: aries2, url:'https://www.trendyol.com/mdot/koc-burcu-dogal-tasli-mum-pembe-kuvars-ametist-kristal-kuvars-p-842677192?boutiqueId=61&merchantId=973914&filterOverPriceListings=false&sav=true' },
    { name: 'Taurus [20 April - 20 May]', description: 'Citrine, Aventurine, Rose Quartz', image: taurus, hoverImage: taurus2, url:'https://www.trendyol.com/mdot/boga-burcu-dogal-tasli-mum-sitrin-aventurin-pembe-kuvars-p-842677073?boutiqueId=61&merchantId=973914&filterOverPriceListings=false&sav=true' },
    { name: 'Gemini [21 May - 20 June]', description: "Tiger's Eye, Citrine, Crystal Quartz", image: gemini, hoverImage: gemini2, url:'https://www.trendyol.com/pd/mdot/ikizler-burcu-dogal-tasli-mum-kaplan-gozu-sitrin-kristal-kuvars-p-842677019?boutiqueId=61&merchantId=973914&filterOverPriceListings=false' },
    { name: 'Cancer [21 June - 22 July]', description: 'Obsidian, Fluorite, Lemon Quartz', image: cancer, hoverImage: cancer2, url:'https://www.trendyol.com/pd/mdot/yengec-burcu-dogal-tasli-mum-obsidyen-florit-limon-kuvars-p-842676740?boutiqueId=61&merchantId=973914&filterOverPriceListings=false' },
    { name: 'Leo [23 July - 22 August]', description: "Sunstone, Tiger's Eye, Crystal Quartz", image: leo, hoverImage: leo2, url:'https://www.trendyol.com/mdot/aslan-burcu-dogal-tasli-mum-gunes-tasi-kaplan-gozu-kristal-kuvars-p-842676978?boutiqueId=61&merchantId=973914&filterOverPriceListings=false&sav=true' },
    { name: 'Virgo [23 August - 22 September]', description: 'Amazonite, Howlite, Hematite', image: virgo, hoverImage: virgo2, url:'https://www.trendyol.com/pd/mdot/basak-burcu-dogal-tasli-mum-amazonit-havlit-hematit-p-842676840?boutiqueId=61&merchantId=973914&filterOverPriceListings=false' },
    { name: 'Libra [23 September - 22 October]', description: 'Rose Quartz, Labradorite, Amazonite', image: libra, hoverImage: libra2, url:'https://www.trendyol.com/pd/mdot/terazi-burcu-dogal-tasli-mum-pembe-kuvars-labradorit-amazonit-p-842676626?boutiqueId=61&merchantId=973914&filterOverPriceListings=false' },
    { name: 'Scorpio [23 October - 21 November]', description: 'Obsidian, Amethyst, Smoky Quartz', image: scorpio, hoverImage: scorpio2, url:'https://www.trendyol.com/pd/mdot/akrep-burcu-dogal-tasli-mum-obsidyen-ametist-dumanli-kuvars-p-842676453?boutiqueId=61&merchantId=973914&filterOverPriceListings=false' },
    { name: 'Sagittarius [22 November - 21 December]', description: 'Sodalite, Obsidian, Hematite', image: sagittarius, hoverImage: sagittarius2, url:'https://www.trendyol.com/pd/mdot/yay-burcu-dogal-tasli-mum-sodalit-obsidyen-hematit-p-842676227?boutiqueId=61&merchantId=973914&filterOverPriceListings=false' },
    { name: 'Capricorn [22 December - 19 January]', description: "Tiger's Eye, Moonstone, Smoky Quartz", image: capricorn, hoverImage: capricorn2, url:'https://www.trendyol.com/pd/mdot/oglak-burcu-dogal-tasli-mum-kaplan-gozu-aytasi-dumanli-kuvars-p-842676035?boutiqueId=61&merchantId=973914&filterOverPriceListings=false' },
    { name: 'Aquarius [20 January - 18 February]', description: 'Rose Quartz, Amethyst, Calcite', image: aquarius, hoverImage: aquarius2, url:'https://www.trendyol.com/pd/mdot/kova-burcu-dogal-tasli-mum-pembe-kuvars-ametist-kalsit-p-842677068?boutiqueId=61&merchantId=973914&filterOverPriceListings=false' },
    { name: 'Pisces [19 February - 20 March]', description: 'Hematite, Amethyst, Moonstone', image: pisces, hoverImage: pisces2, url:'https://www.trendyol.com/pd/mdot/balik-burcu-dogal-tasli-mum-hematit-ametist-aytasi-p-842675632?boutiqueId=61&merchantId=973914&filterOverPriceListings=false' },
  ],
  tr: [
    { name: 'Koç [21 Mart - 19 Nisan]', description: 'Pembe Kuvars, Ametist, Kristal Kuvars', image: aries, hoverImage: aries2, url:'https://www.trendyol.com/mdot/koc-burcu-dogal-tasli-mum-pembe-kuvars-ametist-kristal-kuvars-p-842677192?boutiqueId=61&merchantId=973914&filterOverPriceListings=false&sav=true' },
    { name: 'Boğa [20 Nisan - 20 Mayıs]', description: 'Sitrin, Aventurin, Pembe Kuvars', image: taurus, hoverImage: taurus2, url:'https://www.trendyol.com/mdot/boga-burcu-dogal-tasli-mum-sitrin-aventurin-pembe-kuvars-p-842677073?boutiqueId=61&merchantId=973914&filterOverPriceListings=false&sav=true' },
    { name: 'İkizler [21 Mayıs - 20 Haziran]', description: "Kaplan Gözü, Sitrin, Kristal Kuvars", image: gemini, hoverImage: gemini2, url:'https://www.trendyol.com/pd/mdot/ikizler-burcu-dogal-tasli-mum-kaplan-gozu-sitrin-kristal-kuvars-p-842677019?boutiqueId=61&merchantId=973914&filterOverPriceListings=false' },
    { name: 'Yengeç [21 Haziran - 22 Temmuz]', description: 'Obsidyen, Florit, Limon Kuvars', image: cancer, hoverImage: cancer2, url:'https://www.trendyol.com/pd/mdot/yengec-burcu-dogal-tasli-mum-obsidyen-florit-limon-kuvars-p-842676740?boutiqueId=61&merchantId=973914&filterOverPriceListings=false' },
    { name: 'Aslan [23 Temmuz - 22 Ağustos]', description: "Güneş Taşı, Kaplan Gözü, Kristal Kuvars", image: leo, hoverImage: leo2, url:'https://www.trendyol.com/mdot/aslan-burcu-dogal-tasli-mum-gunes-tasi-kaplan-gozu-kristal-kuvars-p-842676978?boutiqueId=61&merchantId=973914&filterOverPriceListings=false&sav=true' },
    { name: 'Başak [23 Ağustos - 22 Eylül]', description: 'Amazonit, Havlit, Hematit', image: virgo, hoverImage: virgo2, url:'https://www.trendyol.com/pd/mdot/basak-burcu-dogal-tasli-mum-amazonit-havlit-hematit-p-842676840?boutiqueId=61&merchantId=973914&filterOverPriceListings=false' },
    { name: 'Terazi [23 Eylül - 22 Ekim]', description: 'Pembe Kuvars, Labradorit, Amazonit', image: libra, hoverImage: libra2, url:'https://www.trendyol.com/pd/mdot/terazi-burcu-dogal-tasli-mum-pembe-kuvars-labradorit-amazonit-p-842676626?boutiqueId=61&merchantId=973914&filterOverPriceListings=false' },
    { name: 'Akrep [23 Ekim - 21 Kasım]', description: 'Obsidyen, Ametist, Dumanlı Kuvars', image: scorpio, hoverImage: scorpio2, url:'https://www.trendyol.com/pd/mdot/akrep-burcu-dogal-tasli-mum-obsidyen-ametist-dumanli-kuvars-p-842676453?boutiqueId=61&merchantId=973914&filterOverPriceListings=false' },
    { name: 'Yay [22 Kasım - 21 Aralık]', description: 'Sodalit, Obsidyen, Hematit', image: sagittarius, hoverImage: sagittarius2, url:'https://www.trendyol.com/pd/mdot/yay-burcu-dogal-tasli-mum-sodalit-obsidyen-hematit-p-842676227?boutiqueId=61&merchantId=973914&filterOverPriceListings=false' },
    { name: 'Oğlak [22 Aralık - 19 Ocak]', description: "Kaplan Gözü, Aytaşı, Dumanlı Kuvars", image: capricorn, hoverImage: capricorn2, url:'https://www.trendyol.com/pd/mdot/oglak-burcu-dogal-tasli-mum-kaplan-gozu-aytasi-dumanli-kuvars-p-842676035?boutiqueId=61&merchantId=973914&filterOverPriceListings=false' },
    { name: 'Kova [20 Ocak - 18 Şubat]', description: 'Pembe Kuvars, Ametist, Kalsit', image: aquarius, hoverImage: aquarius2, url:'https://www.trendyol.com/pd/mdot/kova-burcu-dogal-tasli-mum-pembe-kuvars-ametist-kalsit-p-842677068?boutiqueId=61&merchantId=973914&filterOverPriceListings=false' },
    { name: 'Balık [19 Şubat - 20 Mart]', description: 'Hematit, Ametist, Aytaşı', image: pisces, hoverImage: pisces2, url:'https://www.trendyol.com/pd/mdot/balik-burcu-dogal-tasli-mum-hematit-ametist-aytasi-p-842675632?boutiqueId=61&merchantId=973914&filterOverPriceListings=false' },
  ]
};

const headings = {
  en: 'zodiac\ncollection',
  tr: 'burç\nkoleksiyonu'
};

const ZodiacCollection = () => {
  const { language } = useLanguage();
  const currentZodiacs = zodiacs[language];
  const heading = headings[language];
  
  // State to track the hovered zodiac index
  const [hoveredIndex, setHoveredIndex] = useState(null);

  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);

  return (
    <div className="zodiac-collection">
      <h2 data-aos='zoom-in'>{heading.split('\n').map((line, index) => (
        <React.Fragment key={index}>{line}<br /></React.Fragment>
      ))}</h2>
      <div className="zodiacs-grid">
        {currentZodiacs.map((zodiac, index) => (
          <a
            href={zodiac.url}
            target="_blank"
            rel="noopener noreferrer"
            className={`zodiac-item ${index % 2 === 0 ? 'offset' : ''}`}
            key={index}
            onMouseEnter={() => setHoveredIndex(index)} // Set hovered index
            onMouseLeave={() => setHoveredIndex(null)} // Reset hovered index
          >
            <div className="zodiac-content">
              <div className="zodiac-info">
                <h3>{zodiac.name}</h3>
                <p>{zodiac.description}</p>
              </div>
              <img 
                src={hoveredIndex === index ? zodiac.hoverImage : zodiac.image} // Display hover image if hovered
                alt={zodiac.name} 
              />
            </div>
          </a>
        ))}
      </div>
    </div>
  );
};

export default ZodiacCollection;
