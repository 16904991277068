import React, { useEffect } from 'react';
import { useLanguage } from '../../LanguageContext';
import './home.css';
import mLogo from '../../assets/mLogo.png'; 

const Home = () => {
  const { language, setLanguage } = useLanguage();

  useEffect(() => {
    setLanguage('tr'); // Set default language to Turkish
  }, [setLanguage]);

  return (
    <div className="home-container">
      <div className="home-logo">
        <img src={mLogo} alt="Logo" /> {/* Logo image */}
        <div className="home-logo-subtext">Candles</div>
      </div>
      <div className="home-language-buttons">
        <button onClick={() => setLanguage('en')} className={language === 'en' ? 'active' : ''}>[en]</button>
        <button onClick={() => setLanguage('tr')} className={language === 'tr' ? 'active' : ''}>[tr]</button>
      </div>
    </div>
  );
};

export default Home;
