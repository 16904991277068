import React, { useEffect, useState } from 'react';
import './crystalCollection.css';
import amethyst from '../../assets/amyethst.png'; 
import roseQuartz from '../../assets/roseQuartz.png';
import citrine from '../../assets/citrine.png';
import tigersEye from '../../assets/tigersEye.png';
import amazonite from '../../assets/amazonite.png';
import sunstone from '../../assets/sunstone.png';
import sodalite from '../../assets/sodalite.png';
import lemonQuartz from '../../assets/lemonQuartz.png';
import hematite from '../../assets/hematite.png';
import flourite from '../../assets/flourite.png';
import moonstone from '../../assets/moonstone.png';
import obsedian from '../../assets/obsedian.png';
import jade from '../../assets/jade.png';

import { useLanguage } from '../../LanguageContext';

import secondAmethyst from '../../assets/amyethst2.jpg'; // Import second images
import secondRoseQuartz from '../../assets/roseQuartz2.jpg';
import secondCitrine from '../../assets/citrine2.jpg';
import secondTigersEye from '../../assets/tigersEye2.jpg';
import secondAmazonite from '../../assets/amazonite2.jpg';
import secondSunstone from '../../assets/sunstone2.jpg';
import secondSodalite from '../../assets/sodalite2.jpg';
import secondLemonQuartz from '../../assets/lemonQuartz2.jpg';
import secondHematite from '../../assets/hematite2.jpg';
import secondFlourite from '../../assets/flourite2.jpg';
import secondMoonstone from '../../assets/moonstone2.jpg';
import secondObsedian from '../../assets/obsedian2.jpg';
import secondJade from '../../assets/jade2.jpg';

import Aos from 'aos';
import 'aos/dist/aos.css';

const crystals = {
  en: [
    { name: 'Amethyst', description: '[Calm & centered]', image: amethyst, secondImage: secondAmethyst, url: 'https://www.trendyol.com/mdot/ametist-kristal-mum-dinginlik-ve-denge-p-839106616?boutiqueId=61&merchantId=973914&filterOverPriceListings=false&sav=true' },
    { name: 'Rose Quartz', description: '[Love & beauty]', image: roseQuartz, secondImage: secondRoseQuartz, url: 'https://www.trendyol.com/mdot/pembe-kuvars-kristal-mum-ask-ve-oz-sevgi-p-839105508?boutiqueId=61&merchantId=973914&filterOverPriceListings=false&sav=true' },
    { name: 'Citrine', description: '[Prosperity & joy]', image: citrine, secondImage: secondCitrine, url: 'https://www.trendyol.com/mdot/sitrin-kristal-mum-nese-ve-sicaklik-p-839099499?boutiqueId=61&merchantId=973914&filterOverPriceListings=false&sav=true' },
    { name: 'Tiger\'s Eye', description: '[Protection & courage]', image: tigersEye, secondImage: secondTigersEye, url: 'https://www.trendyol.com/mdot/kaplan-gozu-kristal-mum-guc-ve-koruma-p-839099537?boutiqueId=61&merchantId=973914&filterOverPriceListings=false&sav=true' },
    { name: 'Amazonite', description: '[Harmony & balance]', image: amazonite, secondImage: secondAmazonite, url: 'https://www.trendyol.com/mdot/amazonit-kristal-mum-manifest-ve-guclenme-p-839071575?boutiqueId=61&merchantId=973914&filterOverPriceListings=false&sav=true' },
    { name: 'Sunstone', description: '[Vitality & joy]', image: sunstone, secondImage: secondSunstone, url: 'https://www.trendyol.com/mdot/gunes-tasi-kristal-mum-enerji-yukseltme-p-839099521?boutiqueId=61&merchantId=973914&filterOverPriceListings=false&sav=true' },
    { name: 'Sodalite', description: '[Logic & intuition]', image: sodalite, secondImage: secondSodalite, url: 'https://www.trendyol.com/mdot/sodalit-kristal-mum-netlik-ve-ic-huzur-p-839099503?boutiqueId=61&merchantId=973914&filterOverPriceListings=false&sav=true' },
    { name: 'Lemon Quartz', description: '[Positive energy]', image: lemonQuartz, secondImage: secondLemonQuartz, url: 'https://www.trendyol.com/pd/mdot/limon-kuvars-kristal-mum-enerji-ve-uyum-p-842675464?boutiqueId=61&merchantId=973914&filterOverPriceListings=false' },
    { name: 'Hematite', description: '[Grounding & protection]', image: hematite, secondImage: secondHematite, url: 'https://www.trendyol.com/pd/mdot/hematit-kristal-mum-guc-ve-denge-p-842675221?boutiqueId=61&merchantId=973914&filterOverPriceListings=false' },
    { name: 'Fluorite', description: '[Clarity & focus]', image: flourite, secondImage: secondFlourite, url: 'https://www.trendyol.com/pd/mdot/florit-kristal-mum-netlik-ve-koruma-p-842675613?boutiqueId=61&merchantId=973914&filterOverPriceListings=false' },
    { name: 'Moonstone', description: '[Intuition & new beginnings]', image: moonstone, secondImage: secondMoonstone, url: 'https://www.trendyol.com/pd/mdot/aytasi-kristal-mum-sukunet-ve-sezgi-p-842674932?boutiqueId=61&merchantId=973914&filterOverPriceListings=false' },
    { name: 'Obsidian', description: '[Protection & healing]', image: obsedian, secondImage: secondObsedian, url: 'https://www.trendyol.com/pd/mdot/obsidyen-kristal-mum-koruma-ve-topraklama-p-842674685?boutiqueId=61&merchantId=973914&filterOverPriceListings=false' },
    { name: 'Jade', description: '[Wisdom & serenity]', image: jade, secondImage: secondJade, url: 'https://www.trendyol.com/pd/mdot/yesim-kristal-mum-uyum-ve-refah-p-842674341?boutiqueId=61&merchantId=973914&filterOverPriceListings=false' },
  ],
  tr: [
    { name: 'Ametist', description: '[dinginlik ve denge]', image: amethyst, secondImage: secondAmethyst, url: 'https://www.trendyol.com/mdot/ametist-kristal-mum-dinginlik-ve-denge-p-839106616?boutiqueId=61&merchantId=973914&filterOverPriceListings=false&sav=true' },
    { name: 'Pembe Kuvars', description: '[aşk ve öz sevgi]', image: roseQuartz, secondImage: secondRoseQuartz, url: 'https://www.trendyol.com/mdot/pembe-kuvars-kristal-mum-ask-ve-oz-sevgi-p-839105508?boutiqueId=61&merchantId=973914&filterOverPriceListings=false&sav=true' },
    { name: 'Sitrin', description: '[neşe ve sıcaklık]', image: citrine, secondImage: secondCitrine, url: 'https://www.trendyol.com/mdot/sitrin-kristal-mum-nese-ve-sicaklik-p-839099499?boutiqueId=61&merchantId=973914&filterOverPriceListings=false&sav=true' },
    { name: 'Kaplan Gözü', description: '[güç ve koruma]', image: tigersEye, secondImage: secondTigersEye, url: 'https://www.trendyol.com/mdot/kaplan-gozu-kristal-mum-guc-ve-koruma-p-839099537?boutiqueId=61&merchantId=973914&filterOverPriceListings=false&sav=true' },
    { name: 'Amazonit', description: '[manifest ve güçlenme]', image: amazonite, secondImage: secondAmazonite, url: 'https://www.trendyol.com/mdot/amazonit-kristal-mum-manifest-ve-guclenme-p-839071575?boutiqueId=61&merchantId=973914&filterOverPriceListings=false&sav=true' },
    { name: 'Güneş Taşı ', description: '[enerji yükseltme]', image: sunstone, secondImage: secondSunstone, url: 'https://www.trendyol.com/mdot/gunes-tasi-kristal-mum-enerji-yukseltme-p-839099521?boutiqueId=61&merchantId=973914&filterOverPriceListings=false&sav=true' },
    { name: 'Sodalit', description: '[netlik ve iç huzur]', image: sodalite, secondImage: secondSodalite, url: 'https://www.trendyol.com/mdot/sodalit-kristal-mum-netlik-ve-ic-huzur-p-839099503?boutiqueId=61&merchantId=973914&filterOverPriceListings=false&sav=true' },
    { name: 'Limon Kuvars', description: '[enerji ve uyum]', image: lemonQuartz, secondImage: secondLemonQuartz, url: 'https://www.trendyol.com/pd/mdot/limon-kuvars-kristal-mum-enerji-ve-uyum-p-842675464?boutiqueId=61&merchantId=973914&filterOverPriceListings=false' },
    { name: 'Hematit', description: '[güç ve denge]', image: hematite, secondImage: secondHematite, url: 'https://www.trendyol.com/pd/mdot/hematit-kristal-mum-guc-ve-denge-p-842675221?boutiqueId=61&merchantId=973914&filterOverPriceListings=false' },
    { name: 'Florit', description: '[netlik ve koruma]', image: flourite, secondImage: secondFlourite, url: 'https://www.trendyol.com/pd/mdot/florit-kristal-mum-netlik-ve-koruma-p-842675613?boutiqueId=61&merchantId=973914&filterOverPriceListings=false' },
    { name: 'Aytaşı', description: '[sükunet ve sezgi]', image: moonstone, secondImage: secondMoonstone, url: 'https://www.trendyol.com/pd/mdot/aytasi-kristal-mum-sukunet-ve-sezgi-p-842674932?boutiqueId=61&merchantId=973914&filterOverPriceListings=false' },
    { name: 'Obsidyen', description: '[koruma ve topraklama]', image: obsedian, secondImage: secondObsedian, url: 'https://www.trendyol.com/pd/mdot/obsidyen-kristal-mum-koruma-ve-topraklama-p-842674685?boutiqueId=61&merchantId=973914&filterOverPriceListings=false' },
    { name: 'Yeşim', description: '[uyum ve refah]', image: jade, secondImage: secondJade, url: 'https://www.trendyol.com/pd/mdot/yesim-kristal-mum-uyum-ve-refah-p-842674341?boutiqueId=61&merchantId=973914&filterOverPriceListings=false' },
  ]
};

const headings = {
  en: 'crystal\ncollection',
  tr: 'doğal taşlı\nkoleksiyonu'
};

const CrystalCollection = () => {
  const { language } = useLanguage();
  const currentCrystals = crystals[language];
  const heading = headings[language];
  
  // State to track the hovered crystal index
  const [hoveredIndex, setHoveredIndex] = useState(null);

  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);

  return (
    <div className="crystal-collection">
      <h2 data-aos='zoom-in'>{heading.split('\n').map((line, index) => (
        <React.Fragment key={index}>{line}<br /></React.Fragment>
      ))}</h2>
      <div className="crystals-grid">
        {currentCrystals.map((crystal, index) => (
          <a
            href={crystal.url}
            target="_blank"
            rel="noopener noreferrer"
            className={`crystal-item ${index % 2 === 0 ? 'offset' : ''}`}
            key={index}
            onMouseEnter={() => setHoveredIndex(index)} // Set hovered index
            onMouseLeave={() => setHoveredIndex(null)} // Reset hovered index
          >
            <div className="crystal-content">
              <div className="crystal-info">
                <h3>{crystal.name}</h3>
                <p>{crystal.description}</p>
              </div>
              <img 
                src={hoveredIndex === index ? crystal.secondImage : crystal.image} // Display second image if hovered
                alt={crystal.name} 
              />
            </div>
          </a>
        ))}
      </div>
    </div>
  );
};

export default CrystalCollection;
