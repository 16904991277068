import React from "react";
import { useLanguage } from "../../LanguageContext";
import "./wellness.css";

// Import images from assets
import peaceGif from "../../assets/peace.gif";
import selfLoveGif from "../../assets/self-love.gif";
import transformGif from "../../assets/transform.gif";
import expressGif from "../../assets/express.gif";
import connectGif from "../../assets/connect.gif";
import compassionGif from "../../assets/compassion.gif";
import focusGif from "../../assets/focus.gif";
import protectGif from "../../assets/protect.gif";
import reflectGif from "../../assets/reflect.gif";
import positivityGif from "../../assets/positivity.gif";
import balanceGif from "../../assets/balance.gif";
import energyGif from "../../assets/energy.gif";

const content = {
  en: {
    heal: {
      title: '[heal]',
      text: 'At M.Candles, we believe in the powerful healing properties of our crystal-infused candles. Each candle is crafted with intention, combining soothing fragrances with the energy of healing crystals like Rose Quartz and Amethyst. These crystals are known to promote emotional balance, reduce stress, and encourage self-love. Light our healing candles to create a serene atmosphere that nurtures your mind, body, and spirit, helping you to release negativity and embrace inner peace.',
    },
    cleanse: {
      title: '[cleanse]',
      text: 'Our crystal-infused candles are perfect for cleansing your space and your aura. Infused with crystals such as Sodalite and Clear Quartz, these candles help to clear away negative energies and promote a fresh, uplifting environment. Whether you’re setting a calming mood for meditation or simply looking to refresh your living space, our cleansing candles provide a gentle, purifying energy that revitalizes and rejuvenates. Let the soothing scents and crystal vibrations leave you with a renewed sense of clarity and peace.',
    },
    energise: {
      title: '[energise]',
      text: 'M.Candles’ energizing crystal-infused candles are designed to uplift and invigorate your senses. With crystals like Citrine and Tiger’s Eye, known for their vibrant and motivating energies, these candles are perfect for boosting your mood and enhancing focus. The stimulating scent, combined with the energizing properties of the crystals, create an atmosphere of positivity and vitality. Light one of our energizing candles to awaken your inner strength, inspire creativity, and bring a burst of fresh energy into your day.',
    },
    icons: [
      { src: peaceGif, label: 'Peace' },
      { src: selfLoveGif, label: 'Self-love' },
      { src: transformGif, label: 'Transform' },
      { src: expressGif, label: 'Express' },
      { src: connectGif, label: 'Connect' },
      { src: compassionGif, label: 'Compassion' },
      { src: focusGif, label: 'Focus' },
      { src: protectGif, label: 'Protect' },
      { src: reflectGif, label: 'Reflect' },
      { src: positivityGif, label: 'Positivity' },
      { src: balanceGif, label: 'Balance' },
      { src: energyGif, label: 'Energy' },
    ],
  },
  tr: {
    heal: {
      title: '[şifa]',
      text: 'M.Candles olarak, kristal dolu mumlarımızın güçlü iyileştirici özelliklerine inanıyoruz. Her bir mum, rahatlatıcı kokuları Gül Kuvars ve Ametist gibi şifalı kristallerin enerjisiyle birleştirerek özenle hazırlanır. Bu kristaller, duygusal dengeyi teşvik etmek, stresi azaltmak ve öz sevgiyi teşvik etmek için bilinir. Negatif enerjileri serbest bırakmanıza ve iç huzuru kucaklamanıza yardımcı olmak için, zihin, beden ve ruhunuzu besleyen huzurlu bir atmosfer yaratmak amacıyla şifalı mumlarımızı yakın.',
    },
    cleanse: {
      title: '[arınma]',
      text: 'Kristal dolu mumlarımız, alanınızı ve auranızı temizlemek için mükemmeldir. Sodalit ve Kuvars gibi kristallerle dolu bu mumlar, olumsuz enerjileri temizlemeye ve taze, canlandırıcı bir ortam yaratmaya yardımcı olur. İster meditasyon için sakinleştirici bir ortam yaratmak, ister yaşam alanınızı tazelemek isteyin, temizlik mumlarımız nazik, arındırıcı bir enerji sağlar ve sizi yenilenmiş bir netlik ve huzur hissi ile bırakır.',
    },
    energise: {
      title: '[enerji]',
      text: "M.Candles'ın enerjilendirici kristal dolu mumları, duyularınızı canlandırmak ve harekete geçirmek için tasarlanmıştır. Canlı ve motive edici enerjileriyle bilinen Sitrin ve Kaplan Gözü gibi kristallerle hazırlanan bu mumlar, ruh halinizi yükseltmek ve odaklanmanızı artırmak için mükemmeldir. Uyarıcı koku, kristallerin enerjilendirici özellikleriyle birleşerek pozitiflik ve canlılık dolu bir atmosfer yaratır. İç gücünüzü uyandırmak, yaratıcılığı teşvik etmek ve gününüze taze bir enerji patlaması getirmek için enerjilendirici mumlarımızdan birini yakın.",
    },
    icons: [
      { src: peaceGif, label: 'Barış' },
      { src: selfLoveGif, label: 'Kendini Sevmek' },
      { src: transformGif, label: 'Dönüştürmek' },
      { src: expressGif, label: 'İfade Etmek' },
      { src: connectGif, label: 'Bağlamak' },
      { src: compassionGif, label: 'Merhamet' },
      { src: focusGif, label: 'Odak' },
      { src: protectGif, label: 'Korumak' },
      { src: reflectGif, label: 'Yansıtmak' },
      { src: positivityGif, label: 'Pozitiflik' },
      { src: balanceGif, label: 'Denge' },
      { src: energyGif, label: 'Enerji' },
    ],
  },
};

const Wellness = () => {
  const { language } = useLanguage();
  const { heal, cleanse, energise, icons } = content[language];

  return (
    <div className="wellness-section">
      <div className="icons-section">
        {icons.map((icon, index) => (
          <div className="icon-item" key={index}>
            <img src={icon.src} alt={icon.label} />
            <p>{icon.label}</p>
          </div>
        ))}
      </div>
      <div className="text-section">
        <div className="text-item">
          <h3>{heal.title}</h3>
          <p>{heal.text}</p>
        </div>
        <div className="text-item">
          <h3>{cleanse.title}</h3>
          <p>{cleanse.text}</p>
        </div>
        <div className="text-item">
          <h3>{energise.title}</h3>
          <p>{energise.text}</p>
        </div>
      </div>
    </div>
  );
};

export default Wellness;
