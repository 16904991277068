import React, { useEffect } from 'react';
import { useLanguage } from '../../LanguageContext';
import './about.css';
import aboutLogo from '../../assets/aboutLogo.png'; // Adjust the path to your image

import Aos from 'aos';
import 'aos/dist/aos.css';
import ScrollToHomeButton from '../ScrollToHomeButton/ScrollToHomeButton';

const About = () => {
  const { language, setLanguage } = useLanguage();

  const content = {
    en: {
      subtitle: '[crystal-infused scented candles]',
      quote: 'Crystals amplify the energy that we transmit, so it’s important to have pure thoughts when we are surrounded by them.',
      author: 'Paulo Coelho'
    },
    tr: {
      subtitle: '[doğal taşlı kokulu mumlar]',
      quote: 'Kristaller, yaydığımız enerjiyi güçlendirir, bu yüzden onların çevresinde saf düşüncelere sahip olmak önemlidir.',
      author: 'Paulo Coelho'
    }
  };

  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);

  return (
    <div className="about-container" id="about-section"> {/* Add an ID for the section */}
      <ScrollToHomeButton /> {/* Add the ScrollToHomeButton to the About component */}
      
      <div className="about-header">
        <div className="about-logo">
          <img src={aboutLogo} alt="Logo" className="about-logo-image" />
        </div>
        <div className="about-language-buttons">
          <button onClick={() => setLanguage('en')} className={language === 'en' ? 'active' : ''}>[en]</button>
          <button onClick={() => setLanguage('tr')} className={language === 'tr' ? 'active' : ''}>[tr]</button>
        </div>
      </div>
      <div className="about-content">
        <div data-aos='fade-up' className="about-subtitle">
          {content[language].subtitle}
        </div>
        <div data-aos='fade-up' className="about-quote">
          "{content[language].quote}"
        </div>
        <div data-aos='fade-up' className="about-author">
          - {content[language].author}
        </div>
      </div>
    </div>
  );
};

export default About;
